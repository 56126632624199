<template>
  <b-row class="match-height">
    <b-col md="12">
      <b-card>
        <b-card-body class="d-md-flex justify-content-md-between align-items-center">
          <div>
            <b-row>
              <b-col cols="auto">
                <b-avatar
                  v-if="!startupData.image"
                  :text="startupData.avatar_text"
                  size="64"
                  variant="primary"
                />
                <b-avatar
                  v-else
                  :src="startupData.image"
                  size="64"
                  variant="primary"
                />
              </b-col>
              <b-col>
                <div class="title">
                  <h5><strong>{{ startupData.title }}</strong></h5>
                </div>
                <div class="text mb-2">
                  {{ startupData.description }}
                </div>
                <b-badge
                  v-for="(item, key) in startupData.marketing_focus"
                  :key="key"
                  pill
                  class="mr-1 mb-1"
                  variant="light-success"
                >
                  {{ item }}
                </b-badge>
                <div v-if="startupData.linkedin">
                  <b-button
                    class="btn-icon"
                    variant="flat-primary"
                    target="_blank"
                    :href="startupData.linkedin"
                  >
                    <FeatherIcon
                      icon="LinkedinIcon"
                      class="mr-1"
                    />
                    <span>Linkedin</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
          <div v-if="startupData.reportView">
            <b-button
              variant="outline-primary"
              :to="'/raporlar/girisim-raporu/' + startupData.id"
            >
              {{ $t('Raporu Görüntüle') }}
            </b-button>
          </div>
          <div v-else>
            <b-button
              v-b-modal.package-view
              variant="outline-primary"
            >
              {{ $t('Raporu Görüntüle') }}
            </b-button>
          </div>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col md="12">
      <b-card
        no-body
        class="card-transaction"
      >
        <b-card-body>
          <b-row>
            <b-col
              md="6"
              class="mb-2"
            >
              <div
                class="transaction-item"
              >
                <b-media no-body>
                  <b-media-aside>
                    <b-avatar
                      rounded
                      size="42"
                      variant="light-success"
                    >
                      <feather-icon
                        size="18"
                        icon="PocketIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h6 class="transaction-title">
                      {{ $t('Arge') }}
                    </h6>
                    <small>R&D</small>
                  </b-media-body>
                </b-media>
                <div
                  class="font-weight-bolder text-success"
                >
                  %{{ startupData.rd_percent }}
                </div>
              </div>
            </b-col>
            <b-col
              md="6"
              class="mb-2"
            >
              <div
                class="transaction-item"
              >
                <b-media no-body>
                  <b-media-aside>
                    <b-avatar
                      rounded
                      size="42"
                      variant="light-warning"
                    >
                      <feather-icon
                        size="18"
                        icon="TrendingUpIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h6 class="transaction-title">
                      {{ $t('Pazarlama & Satış & Dağıtım') }}
                    </h6>
                    <small>S&M</small>
                  </b-media-body>
                </b-media>
                <div
                  class="font-weight-bolder text-warning"
                >
                  %{{ startupData.sm_percent }}
                </div>
              </div>
            </b-col>
            <b-col
              md="6"
              class="mb-2"
            >
              <div
                class="transaction-item"
              >
                <b-media no-body>
                  <b-media-aside>
                    <b-avatar
                      rounded
                      size="42"
                      variant="light-danger"
                    >
                      <feather-icon
                        size="18"
                        icon="AwardIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h6 class="transaction-title">
                      {{ $t('Genel Yönetim') }}
                    </h6>
                    <small>G&A</small>
                  </b-media-body>
                </b-media>
                <div
                  class="font-weight-bolder text-danger"
                >
                  %{{ startupData.ga_percent }}
                </div>
              </div>
            </b-col>
            <b-col
              md="6"
              class="mb-2"
            >
              <div
                class="transaction-item"
              >
                <b-media no-body>
                  <b-media-aside>
                    <b-avatar
                      rounded
                      size="42"
                      variant="light-info"
                    >
                      <feather-icon
                        size="18"
                        icon="CreditCardIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h6 class="transaction-title">
                      {{ $t('Satılan Mal Maliyeti') }}
                    </h6>
                    <small>COGS</small>
                  </b-media-body>
                </b-media>
                <div
                  class="font-weight-bolder text-info"
                >
                  %{{ startupData.cogs_percent }}
                </div>
              </div>
            </b-col>
            <b-col
              md="6"
              class="mb-2"
            >
              <div
                class="transaction-item"
              >
                <b-media no-body>
                  <b-media-aside>
                    <b-avatar
                      rounded
                      size="42"
                      variant="light-primary"
                    >
                      <feather-icon
                        size="18"
                        icon="CommandIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h6 class="transaction-title">
                      {{ $t('OPEX Operasyon Giderleri') }}
                    </h6>
                    <small>OPEX</small>
                  </b-media-body>
                </b-media>
                <div
                  class="font-weight-bolder text-primary"
                >
                  %{{ startupData.opex_percent }}
                </div>
              </div>
            </b-col>
            <b-col
              md="6"
              class="mb-2"
            >
              <div
                class="transaction-item"
              >
                <b-media no-body>
                  <b-media-aside>
                    <b-avatar
                      rounded
                      size="42"
                      variant="light-success"
                    >
                      <feather-icon
                        size="18"
                        icon="ClipboardIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h6 class="transaction-title">
                      {{ $t('CAPEX Operasyon Giderleri') }}
                    </h6>
                    <small>CAPEX</small>
                  </b-media-body>
                </b-media>
                <div
                  class="font-weight-bolder text-success"
                >
                  %{{ startupData.capex_percent }}
                </div>
              </div>
            </b-col>
            <b-col
              md="6"
            >
              <div
                class="transaction-item"
              >
                <b-media no-body>
                  <b-media-aside>
                    <b-avatar
                      rounded
                      size="42"
                      variant="light-warning"
                    >
                      <feather-icon
                        size="18"
                        icon="MoreHorizontalIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h6 class="transaction-title">
                      {{ $t('Diğer') }}
                    </h6>
                    <small>Other</small>
                  </b-media-body>
                </b-media>
                <div
                  class="font-weight-bolder text-warning"
                >
                  %{{ startupData.other_percent }}
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col cols="12">
      <b-card>
        <b-tabs
          pills
          align="left"
        >
          <b-tab class="pt-2 pb-2">
            <template #title>
              <feather-icon icon="HomeIcon" />
              <span>{{ $t('Genel Bilgiler') }}</span>
            </template>
            <b-card-text>
              <h5>{{ $t('Genel Bakış') }}</h5>
              <p>{{ startupData.content }}</p>
              <hr>
              <h5 class="mb-2">
                {{ $t('Temel Bilgiler') }}
              </h5>
              <b-row>
                <b-col md="3">
                  <p class="m-0 font-small-3">
                    {{ $t('Web Sitesi') }}
                  </p>
                  <strong><a
                    :href="startupData.website"
                    target="_blank"
                  >{{ startupData.website }}</a></strong>
                </b-col>
                <b-col md="3">
                  <p class="m-0 font-small-3">
                    {{ $t('E-Posta') }}
                  </p>
                  <strong><a
                    :href="'mailto:' + startupData.email"
                  >{{ startupData.email }}</a></strong>
                </b-col>
                <b-col>
                  <p class="m-0 font-small-3">
                    {{ $t('Faaliyet Alanı') }}
                  </p>
                  <b-badge
                    v-for="(item, aKey) in startupData.activity_area"
                    :key="aKey"
                    variant="light-success"
                    pill
                    class="mr-1 mb-1"
                  >
                    {{ item }}
                  </b-badge>
                </b-col>
              </b-row>
            </b-card-text>
          </b-tab>
          <b-tab class="pt-2 pb-2">
            <template #title>
              <feather-icon icon="UsersIcon" />
              <span>{{ $t('Ekip') }}</span>
            </template>
            <b-card-text>
              <b-row>
                <b-col
                  v-for="(team, tKey) in startupData.teams"
                  :key="tKey"
                  lg="4"
                  md="3"
                  class="mb-2"
                >
                  <b-row>
                    <b-col
                      cols="auto"
                    >
                      <b-avatar
                        :text="team.avatar_text"
                        size="64"
                        variant="primary"
                      />
                    </b-col>
                    <b-col>
                      <div class="title">
                        <strong>{{ team.name }}</strong>
                      </div>
                      <div class="text">
                        {{ team.task }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card-text>
          </b-tab>
          <b-tab class="pt-2 pb-2">
            <template #title>
              <feather-icon icon="PieChartIcon" />
              <span>{{ $t('Ürün & Pazar') }}</span>
            </template>
            <b-card-text>
              <h5>{{ $t('Problem') }}</h5>
              <p>{{ startupData.problems }}</p>
              <hr>
              <h5>{{ $t('Çözüm') }}</h5>
              <p>{{ startupData.solutions }}</p>
            </b-card-text>
          </b-tab>
          <b-tab class="pt-2 pb-2">
            <template #title>
              <feather-icon icon="BarChartIcon" />
              <span>{{ $t('Finans') }}</span>
            </template>
            <b-card-text>
              <h5>{{ $t('Alınan Ödül, Yatırım, Teşvik ve Destekler') }}</h5>
              <p>{{ startupData.award_supports }}</p>
              <hr>
              <h5>{{ $t('Talep Edilen Yatırım Miktarı') }}</h5>
              <p>{{ startupData.demand_investment | toCurrency }}₺</p>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-col>
    <b-modal
      id="package-view"
      centered
      :title="$t('Paketler')"
      ok-only
      hide-footer
      size="lg"
      content-class="shadow"
      ok-title="Accept"
    >
      <view-packages
        :startup-id="startupData.id"
        :startup-title="startupData.title"
      />
    </b-modal>
  </b-row>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BButton,
  BTabs,
  BTab,
  BCardBody,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardText,
  BModal,
} from 'bootstrap-vue'
import ViewPackages from '@/views/App/Packages/ViewPackages.vue'

export default {
  name: 'ViewStartup',
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BButton,
    BTabs,
    BTab,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardText,
    BModal,
    ViewPackages,
  },
  computed: {
    startupData() {
      return this.$store.getters['startups/getStartup']
    },
  },
  created() {
    this.getStartup()
  },
  methods: {
    getStartup() {
      this.$store.dispatch('startups/startupView', this.$route.params.seourl)
    },
  },
}
</script>

<style scoped>

</style>
